const state = () => ({
  filters: {
    category: [],
    end_date: null,
    kanton: [],
    language: [],
    media_type: [],
    region: [],
    source: [],
    start_date: [],
    topic_dossier: [],
  },
  currentPage: 1,
  sq: ""
})

// getters
const getters = {
  filters: (state) => {
    return state.filters
  },

  currentPage: (state) => {
    return state.currentPage
  },
  searchQuery: (state) => {
    return state.sq
  }
}

// mutations
const mutations = {
  setFilters (state, filters) {
    state.filters = filters
  },

  setCurrentPage (state, page) {
    state.currentPage = page
  },
  setSearchQuery (state, sq) {
    state.sq = sq
  }
}

// actions
const actions = {
  search({ commit, state }, { filters, page ,sq}) {
    commit('setFilters', filters)
    commit('setCurrentPage', page)
    commit('setSearchQuery', sq)
  },
  reset({commit,state}){
    commit('setFilters', {
      category: [],
      end_date: null,
      kanton: [],
      language: [],
      media_type: [],
      region: [],
      source: [],
      start_date: [],
      topic_dossier: [],
    })
    commit('setCurrentPage', 1)
    commit('setSearchQuery', "")
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

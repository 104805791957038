<script>
import AtomicView from "./atomic-view";
import { eventBus, COMPANY_TOKEN_KEY } from "../../../_shared/constants";

export default {
  name: "ListingItem",
  components: { AtomicView },
  props: {
    article: Object,
    categoryId: String,
    section: String,
    displayCategory: {
      type: Boolean,
      default: false,
    },
    displayActions: {
      type: Boolean,
      default: true,
    },
    displaySelectionCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      itemSelected: false,
    };
  },
  computed: {
    multiArticles() {
      if (
        this.article &&
        this.article.header &&
        this.article.header.object_type == "group_article"
      ) {
        let newArticles = [...this.article.articles];
        newArticles.shift();

        return newArticles;
      }

      return this.article.articles;
    },
    isMultiArticle() {
      if (
        this.article &&
        this.article.header &&
        this.article.header.object_type == "group_article"
      ) {
        return true;
      }

      // bundled articles?
      if (
        this.article &&
        this.article.header &&
        this.article.header.object_type == "bundle_article"
      ) {
        return true;
      }
      return false;
    },
    classObject() {
      let grouped = false;
      let bundled = false;
      // grouped articles?
      if (
        this.article &&
        this.article.header &&
        this.article.header.object_type == "group_article"
      ) {
        grouped = true;
      }

      // bundled articles?
      if (
        this.article &&
        this.article.header &&
        this.article.header.object_type == "bundle_article"
      ) {
        bundled = true;
      }

      return {
        "article-item": true,
        "pvw-group-wrapper": true,
        "article-item--grouped": grouped,
        "article-item--bundled": bundled,
      };
    },
  },
  created() {},
  mounted() {},
  methods: {
    articleHasGroupChildren(article) {
      if (article.group_children) {
        return true;
      }
      return false;
    },
    openArticle(articleSpecificId) {
      console.log("openArticle");
      // window.location.href = this.$_routesMixin_companyUrl(`articles/${this.article.id}`)
      // the correct way, so we won't lose any vuex data
      const token = window[COMPANY_TOKEN_KEY];
      console.log("token: " + token);
      console.log("slug: " + this.article.header.short_link_slug);
      this.$router.push({
        name: "articleView",
        params: {
          companyToken: token,
          slug: this.article.header.short_link_slug,
          specificArticleId: articleSpecificId,
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <div :class="classObject">
      <div>
        <span
          class="icon icon-toggle article-group-collapser"
          v-if="isMultiArticle"
          v-b-toggle="`collapse-${article.header.id}`"
        ></span>

        <AtomicView
          :article="article.header"
          :mainArticleId="article.header.id"
          :publication="article.publication"
          :categoryId="categoryId"
          :section="section"
          :displayCategory="displayCategory"
          :displayActions="displayActions"
          :displaySelectionCheckbox="displaySelectionCheckbox"
          @triggerOpenArticle="openArticle"
        ></AtomicView>
      </div>

      <div v-if="isMultiArticle" class="article-child-list">
        <b-collapse :id="`collapse-${article.header.id}`">
          <div v-for="child_article in multiArticles" :key="child_article.id">
            <div v-if="articleHasGroupChildren(child_article)">
              <ListingItem
                :article="child_article.group_children"
                :key="child_article.group_children.header.id"
              />
            </div>
            <div v-else>
              <AtomicView
                :mainArticleId="article.header.id"
                :article="child_article"
                :categoryId="categoryId"
                :publication="article.publication"
                :section="section"
                :displayCategory="displayCategory"
                :displayActions="displayActions"
                :displaySelectionCheckbox="displaySelectionCheckbox"
                @triggerOpenArticle="openArticle"
              ></AtomicView>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div v-if="isMultiArticle" class="bundlespacer"></div>
  </div>
</template>

<style lang="scss" scoped></style>

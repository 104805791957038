import axios from "axios"

export const searchRequest = payload => {
  return axios.post("/api/intranet/v1/search", { ...payload })
}

export const getSearchOptionsRequest = () => {
  return axios({
    method: "GET",
    url: "/api/intranet/v1/search/options",
  })
}

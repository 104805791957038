<script>
/* ATTENTION - this one can only be used by article-modal-view && article-view
  DO NOT USE THIS ONE IN ANOTHER section without prior checking the code and designs
*/
import { downloadArticlesPdfRequest } from "../../_shared/api";
import { getArticleList } from "./api";

import {
  eventBus,
  EVENT_SHOW_GLOBAL_LOADING,
  EVENT_HIDE_GLOBAL_LOADING
} from "../../_shared/constants";

//import ArticleSelectionCheckbox from "../_shared/article-selection-checkbox";
import TocEntry from "./toc-entry.vue";
import ArticleCommentary from "./article-commentary";
import { showInfoToast } from "../../_shared/toaster";

export default {
  props: {
    article: Object,
    articleId: Number,
    articleSpecificId: Number
  },
  components: {
    // ArticleSelectionCheckbox,
    TocEntry,
    ArticleCommentary
  },
  data() {
    return {
      mainArticle: {},
      loading: false,
      imageViewModalContent: null,
    };
  },
  watch: {
    article(newVal, oldVal) {
      console.log("article changed");
    },
    articleSpecificId() {
      console.log("articleSpecificId changed");
    }
  },
  mounted() {
    this.fetchArticle()
  },
  methods: {
    handleClick(e) {
      if (e.target.matches('img')) {
        const imageElement = e.target;
        const imageHTML = `<img src="${imageElement.getAttribute('src')}" style="max-width: 100%"/>`
        this.imageViewModalContent = imageHTML;
        this.$bvModal.show("image-view-modal");
      }
    },
    fetchArticle(){
      let article_ids = this.article.articles.map(item => {return item.id})
      getArticleList(article_ids).then((response) => {
        // ok, we wont set the response data to the article
        // because all the policy stuff which happened earlier
        // have a look at VisibleChilds concern, ArticleTransformService or
        // MagicSelectionResultBuilderService would be neglected
        // this.article knows what it has to display but it's missing the content and some metadata
        // so, instead of just setting all the data, we will extract the single article data
        for(let i = 0; i < this.article.articles.length; i++){
          for(let x = 0; x < response.data.data.length; x++){
            if(this.article.articles[i].id == response.data.data[x].id){
              this.article.articles[i] = response.data.data[x];
            }
          }
        }
        
        if (this.articleSpecificId != 0) {
          this.setMainArticle(this.articleSpecificId);
        } else {
          this.setMainArticle(this.article.header.id);
        }

        this.loading = false;
      });
    },
    copyDirectLink() {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", (e) => e.stopPropagation());
      el.value = this.directLinkUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      showInfoToast(
        this,
        this.$i18n.t("toasts.messages.direct_link_copied_to_clipboard"),
        this.$i18n.t("toasts.titles.success")
      );
    },
    changeMainArticleComplete(article){
      this.article = article
      this.setMainArticle(article.header.id)
    },
    setMainArticle(id) {
      if (this.article && this.article.articles) {
        for (let i = 0; i < this.article.articles.length; i++) {
          if (this.article.articles[i].id == id) {
            this.mainArticle = this.article.articles[i];
          }
        }
        if (!this.mainArticle.id) {
          this.mainArticle = this.article.header; //this.article.articles[0];
          if(!this.mainArticle.content){
            this.mainArticle.content = this.mainArticle.excerpt
          }
        }
      }
    },
    downloadPdf() {
      downloadArticlesPdfRequest([this.mainArticle.id]).then(() => {});
    }
  },
  computed: {
     kantonNames(){
      if(this.mainArticle && this.mainArticle.kantons && this.mainArticle.kantons.length > 0){
        const t = this.mainArticle.kantons.map(item => item.short_name)
        return t.join(",")
      }
      return false
    },
    hasSourceDirectLink() {
      if (
        this.mainArticle.direct_link_url &&
        this.mainArticle.direct_link_url.length > 0
      ) {
        return true;
      }
      return false;
    },
    hasSourceComposites() {
      // appeared same day in other sources
      if (
        this.mainArticle &&
        this.mainArticle.source_composites &&
        this.mainArticle.source_composites.length > 0
      ) {
        return true;
      }
      return false;
    },
    hasCommentaries() {
      if (
        this.mainArticle &&
        this.mainArticle.commentaries &&
        this.mainArticle.commentaries.length > 0
      ) {
        return true;
      }
      return false;
    },
    tocEntries() {
      let t = [];
      if (this.article && this.article.articles) {
        for (let i = 0; i < this.article.articles.length; i++) {
          if (this.article.articles[i].id != this.mainArticle.id) {
            t.push(this.article.articles[i]);
          }
        }
      }
      return t;
    },
    hasToc() {
      if (this.article && this.article.articles.length > 1) {
        return true;
      }
      return false;
    },
    isGroupToc() {
      if (
        this.article &&
        this.article.header &&
        this.article.header.object_type == "group_article"
      ) {
        return true;
      }
      return false;
    },
    isBundleToc() {
      if (this.article && this.article.header.e_media_type == "emt_bundle") {
        if (this.mainArticle && this.mainArticle.id != this.article.header.id) {
          return true;
        }
      }
      return false;
    },
    articles() {
      if (this.article && this.article.articles) {
        return this.article.articles;
      }
      return [];
    },
    isPrintArticle() {
      if (this.mainArticle && this.mainArticle.e_media_type == "emt_print") {
        return true;
      }
      return false;
    },
    isOnlineArticle() {
      if (this.mainArticle && this.mainArticle.e_media_type == "emt_online") {
        return true;
      }
      return false;
    },
    isTvRadioArticle() {
      if (this.mainArticle && this.mainArticle.e_media_type == "emt_tv_radio") {
        return true;
      }
      return false;
    },
    isBundleArticle() {
      if (this.mainArticle && this.mainArticle.e_media_type == "emt_bundle") {
        return true;
      }
      return false;
    }
  }
};
</script>

<template>
  <div v-if="!loading">
    <b-modal id="image-view-modal" size="xl" class="image-view-modal" hide-footer hide-header>
      <div class="text-center" v-html="imageViewModalContent"></div>
    </b-modal>

    <div class="article-item__body article-item_single pvw-wrapper no-border">
      <div class="container">
        <div class="row">
          <div class="col-12 col-iom-9 pr-4" @click="handleClick">
            <div class="col-wrapper pvw-actions mb-4">
              <div class="col-left">
                <h6 class="top_headline" v-if="mainArticle">
                  <strong>{{ mainArticle.category_name }}</strong>
                </h6>
              </div>
              <div class="action-col text-right">
                <div class="a-element" alt="PDF generieren">
                  <i class="icon icon-generate-pdf" @click="downloadPdf"></i>
                </div>
              </div>
            </div>
            <div v-if="mainArticle" class="pvw-headline">
              <h1 v-html="mainArticle.title"></h1>
            </div>

            <div v-if="!isBundleArticle">
              <div class="col-wrapper pvw-actions my-4">
                <div class="info-col">
                  <ul class="link-icons">
                    <li class="fs125">
                      <span
                        class="icon icon-newspaper"
                        v-if="isPrintArticle"
                      ></span>
                      <span
                        class="icon icon-online"
                        v-if="isOnlineArticle"
                      ></span>
                      <span
                        class="icon icon-radiotv"
                        v-if="isTvRadioArticle"
                      ></span>
                    </li>
                  </ul>
                  <ul class="quelle" v-if="mainArticle">
                    <li v-if="hasSourceDirectLink == false">
                      {{ mainArticle.source_name }}
                    </li>
                    <li v-if="hasSourceDirectLink">
                      <a :href="mainArticle.direct_link_url" target="_blank">{{
                        mainArticle.source_name
                      }}</a>
                    </li>
                    <li>
                      {{ mainArticle.source_publishing_date | moment("DD.MM.YYYY") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="pvw-content content-wrapper">
                <div
                  v-html="mainArticle.content"
                  v-if="mainArticle"
                  class="first-child-notop-space"
                ></div>
              </div>
            </div>

            <div v-if="isBundleArticle">
              <div class="pvw-content bundle-content">
                <div v-html="mainArticle.content" v-if="mainArticle"></div>
              </div>
              <div class="col-wrapper pvw-actions my-4">
                <div class="info-col">
                  <ul class="link-icons">
                    <li class="fs125">
                      <span class="icon icon-media-echo icon-orange"></span>
                    </li>
                  </ul>
                  <ul class="quelle">
                    <li>
                      {{ $t("articleitem.echo") }}
                    </li>
                    <li>
                      {{ article.articles.length }}
                      {{ $t("words.articles") }}
                    </li>
                    <li>
                      {{ mainArticle.source_publishing_date | moment("DD.MM.YYYY") }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-iom-3">
            <div class="sourceinfo">
              <div class="source_info_headline" v-if="mainArticle">
                <h6>{{ $t("articleitem.metainfo") }}</h6>
              </div>
              <div
                class="source_logo"
                v-if="mainArticle && mainArticle.source_logo_url"
              >
                <img :src="mainArticle.source_logo_url" />
              </div>
              <p v-if="mainArticle && mainArticle.content_type">
                <strong>{{ $t("article.content_type") }}</strong
                ><br />{{ mainArticle.content_type }}
              </p>
              <!--p v-if="article.header.source_name">
                <strong>Quelle</strong><br />{{ article.header.source_name }}
              </p-->
              <p v-if="mainArticle.source_publishing_date">
                <strong>{{ $t("article.source_publishing_date") }}</strong
                ><br />{{
                  mainArticle.source_publishing_date | moment("DD.MM.YYYY")
                }}
              </p>
              <p v-if="mainArticle.broadcaster">
                <strong>{{ $t("article.broadcaster") }}</strong
                ><br />{{ mainArticle.broadcaster }}
              </p>
              <p v-if="mainArticle.page_count">
                <strong>{{ $t("article.page_count") }}</strong
                ><br />{{ mainArticle.page_count }}
              </p>
              <p v-if="mainArticle.print_appearance">
                <strong>{{ $t("article.print_appearance") }}</strong
                ><br />{{ mainArticle.print_appearance }}
              </p>
              <p v-if="kantonNames">
                <strong>{{ $t("article.kanton") }}</strong
                ><br />{{ kantonNames }}
              </p>
              <p v-if="mainArticle.region_short_name">
                <strong>{{ $t("article.region") }}</strong
                ><br />{{ mainArticle.region_name }}
              </p>
            </div>
          </div>
        </div>

        <!-- Artikelzusatz -->
        <div v-if="hasCommentaries">
          <ArticleCommentary
            :commentary="item"
            v-for="item in mainArticle.commentaries"
            :key="item.id"
          />
        </div>

        <!-- Gleichentags erschienen -->
        <div class="row" v-if="hasSourceComposites">
          <div class="col-12 col-iom-9 pr-4">
            <div class="same-day-published-wrapper">
              <p class="same-day-published-title">
                {{ $t("article_view.same-day-published.same-day-title") }}
              </p>
              <ul>
                <li
                  v-for="item in mainArticle.source_composites"
                  :key="item.id"
                >
                  <a :href="item.url" target="_blank" v-if="item.url">{{
                    item.source_name
                  }}</a>
                  <span v-else>{{ item.source_name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- TOC falls Gruppe oder bundle -->
        <div class="row" v-if="hasToc">
          <div class="col-12 col-iom-9 pr-4">
            <div class="toc">
              <h2 class="toc-headline" v-if="isGroupToc">
                {{ $t("article_view.toc.group_title") }}
              </h2>
              <h2 class="toc-headline" v-if="isBundleToc">
                {{ $t("article_view.toc.bundle_title") }}
              </h2>
              <div v-for="toc_entry in tocEntries" :key="toc_entry.id">
                <TocEntry
                  :article="toc_entry"
                  :mainArticleType="article.header.object_type"
                  @changeMainArticle="setMainArticle"
                  @changeMainArticleComplete="changeMainArticleComplete"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-single-view-footer">
      <div class="">
        <div class="row">
          <div class="col-6 offset-6 text-right">
            <!-- <ArticleSelectionCheckbox
              :article="mainArticle"
              v-if="mainArticle.id"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<template>
  <div class="commentary-entry">
    
    <div class="row"> 
      <div class="col-12 col-iom-9 pr-4">
        <div v-html="commentary.content" class="first-child-notop-space"></div>
      </div>
      <div class="col-12 col-iom-3">
        <div class="sourceinfo">
          <div class="source_info_headline">
            <h6>{{ $t("articleitem.metainfo") }}</h6>
          </div>
          <p v-if="showContentTypes">
            <strong>{{ $t("article.content_type") }}</strong
            ><br />{{ commentary.content_type }}
          </p>
          <p v-if="showPageNumber">
            <strong>{{ $t("article.page_count") }}</strong
            ><br />{{ commentary.page_number }}
          </p>
          <p v-if="showDate">
            <strong>{{ $t("article.commentary_date") }}</strong
            ><br />{{ commentary.date | moment("DD.MM.YYYY") }}
          </p>
          <p v-if="showBroadcaster">
            <strong>{{ $t("article.broadcaster") }}</strong
            ><br />{{ commentary.broadcaster }}
          </p>
          <!--<p v-if="showUrl">
            <strong>{{ $t("article.url") }} </strong
            ><a :href="commentary.url" target="_blank"
              ><span class="icon icon-duplicate"></span></a><br /><a :href="commentary.url" target="_blank"
              >{{ commentary.url }}</a>
          </p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commentary: Object
  },
  computed: {
    showContentTypes() {
      if (this.commentary.e_media_type == "emt_print" && this.commentary.duration) {
        return true;
      }
      return false;
    },
    showPageNumber() {
      if (this.commentary.e_media_type == "emt_print" && this.commentary.page_number) {
        return true;
      }
      return false;
    },
    showDate() {
      if (
        this.commentary.e_media_type == "emt_online" ||
        this.commentary.e_media_type == "emt_tv_radio" ||
        this.commentary.e_media_type == "emt_tv_radio_online"
      ) {
        return true;
      }
      return false;
    },
    showBroadcaster() {
      if (this.commentary.e_media_type == "emt_tv_radio" && this.commentary.broadcaster) {
        return true;
      }
      return false;
    },
    showDuration() {
      if (this.commentary.e_media_type == "emt_tv_radio") {
        return true;
      }
      return false;
    },
    showStartTime() {
      if (this.commentary.e_media_type == "emt_tv_radio" && this.commentary.start_time) {
        return true;
      }
      return false;
    },
    showUrl() {
      if (
        this.commentary.url && (
        this.commentary.e_media_type == "emt_online" ||
        this.commentary.e_media_type == "emt_tv_radio" ||
        this.commentary.e_media_type == "emt_tv_radio_online")
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style></style>

<script>
export default {}
</script>

<template>
  <div>
    Intranet App
  </div>
</template>

<style scoped lang="scss">
</style>

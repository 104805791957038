import {
  getCollectionFromLocalStorage,
  setCollectionToLocalStorage,
  uniqArray,
  removeValueFromArray,
} from "./helpers"

const SELECTED_ARTICLE_IDS_KEY = "SELECTED_ARTICLE_IDS"

export const getSelectedArticleIds = () => {
  return getCollectionFromLocalStorage(SELECTED_ARTICLE_IDS_KEY)
}

export const setSelectedArticleIds = (articleIds) => {
  setCollectionToLocalStorage(SELECTED_ARTICLE_IDS_KEY, articleIds)
}

export const resetSelectedArticleIds = () => {
  return setCollectionToLocalStorage(SELECTED_ARTICLE_IDS_KEY, [])
}

export const selectArticle = (id) => {
  let originalIds = getSelectedArticleIds()
  const articleIds = uniqArray([...originalIds, id])

  setSelectedArticleIds(articleIds)

  return articleIds
}

export const selectMultipleArticles = (ids = []) => {
  let originalIds = getSelectedArticleIds()

  const articleIds = uniqArray([...originalIds, ...ids])
  setSelectedArticleIds(articleIds)

  return articleIds
}

export const deselectArticle = (id) => {
  let originalIds = getSelectedArticleIds()

  const articleIds = removeValueFromArray(originalIds, id)
  setSelectedArticleIds(articleIds)

  return articleIds
}

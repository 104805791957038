<script>
/*eslint no-unused-vars: "off"*/

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  eventBus,
  EVENT_RESET_SEARCH_DATEPICKER,
} from "../../_shared/constants";

export default {
  components: { DateRangePicker },
  computed: {
    classNames() {
      // return `filter filter_${this.filterKey}`;
      if (this.dateRange.startDate && this.dateRange.endDate) {
        return `filter filter-filled filter_${this.filterKey}`;
      }
      return `filter filter_${this.filterKey}`;
    },
    isDateSet() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        return true;
      }
      return false;
    },
  },
  props: {
    label: String,
  },
  data() {
    let t_localeData = {
      direction: "ltr",
      format: "yyyy-mm-dd",
      separator: " ~ ",
      applyLabel: this.$i18n.t("actions.apply"),
      cancelLabel: this.$i18n.t("actions.cancel"),
      weekLabel: "W",
      customRangeLabel: "Custom Range",
      daysOfWeek: this.$i18n.t("date_and_time.days_of_week"),
      monthNames: this.$i18n.t("date_and_time.month_names_long"),
      firstDay: 0,
      autoApply: true,
    };

    return {
      dateRange: {},
      localeData: t_localeData,
    };
  },
  mounted() {
    eventBus.$on(EVENT_RESET_SEARCH_DATEPICKER, () => {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    });
  },
  methods: {
    fetchData(range) {
      let start_date = this.$moment(range.startDate).format("YYYY-MM-DD");
      let end_date = this.$moment(range.endDate).format("YYYY-MM-DD");

      this.$emit("date_changed", start_date, end_date);
    },
  },
};
</script>

<template>
  <div :class="classNames">
    <date-range-picker
      v-model="dateRange"
      :locale-data="localeData"
      @update="fetchData"
      ,
      :ranges="false"
    >
      <template #input="picker" style="min-width: 350px;">
        {{ label }}
        <span v-if="isDateSet">
          {{ picker.startDate | moment("DD.MM.YYYY") }} -
          {{ picker.endDate | moment("DD.MM.YYYY") }}
        </span>
      </template>
    </date-range-picker>
  </div>
</template>

<style lang="scss" scoped>
.filter {
  margin-right: 5px;
}

::v-deep {
  .form-control {
    color: #aaa;
  }
}
</style>

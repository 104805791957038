<script>
import { eventBus } from "../../_shared/constants";
import { getArticle,getArticleFromSlug } from "./api";
import Article from "../article";

export default {
  components: {
    Article
  },
  data() {
    return {
      article: null,
      articleSpecificId: 0
    };
  },
  methods: {
    fetchArticle() {
      const slug = this.$router.history.current.params.slug;
      this.articleSpecificId = parseInt(
        this.$router.history.current.params.specificArticleId
      );
      console.log(this.$router.history.current.params);
      getArticleFromSlug(slug).then((response) => {
        this.article = response.data.data[0];
      });
    },
    handleClickBack() {
      this.$router.push({ name: 'search', params: {token: window[COMPANY_TOKEN_KEY]}})
      //this.$router.go(-1);
    }
  },
  mounted() {
    this.fetchArticle();
  }
};
</script>

<template>
  <div class="wrapper content-wrapper">
    <div class="content-col">
      <div class="content-col-header">
        <div class=" col-wrapper">
          <div class="left-col">
            <div class="col-header-icons">
              <a @click="handleClickBack()" class="backlink"
                ><span class="icon icon-arrow-left"></span
                >{{ $t("actions.back") }}</a
              >
            </div>
          </div>
          <div class="right-col"></div>
        </div>
      </div>

      <div class="content-col-body">
        <Article
          :article="article"
          v-if="article"
          :article-specific-id="articleSpecificId"
        />
      </div>
    </div>
  </div>
</template>

<style></style>

import 'core-js/stable'

import Vue from "vue"
import VueI18n from "vue-i18n"
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"

import App from "./App.vue"
import router from "./router"
import localizations from "./i18n"
import axios from "axios"
import Vuelidate from "vuelidate"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "./assets/scss/index.scss"
import { eventBus } from "./_shared/constants"
import UseCompanyUrlMixin from "./mixins/routesMixin.vue"
import store from './store'
import Meta from 'vue-meta'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-M2LBKRJ3Y3" }
});

Vue.use(Meta)
Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(require("vue-moment"))

// browser specific language, fallback is german
var lang = navigator.language || navigator.userLanguage;

if(lang.length > 2){
  lang = lang.substring(0,2);
}

if(lang != "de" && lang!= "fr" && lang != "it"){
  lang = "de";
}

const i18n = new VueI18n({
  locale: lang,
  messages: localizations,
})

axios.interceptors.request.use(function(config) {
  config.params = {...config.params, locale: i18n.locale}

  return config;
});

axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function(error) {
    if (error !== null) {
      const alertParameters = {
        alertType: "danger",
        message: error,
      }

      eventBus.$emit("alert", alertParameters)

      if (error.response.status == 403) {
        window.location.href = "/access-denied"
      }
    }

    return Promise.reject(error)
  }
)

Vue.mixin(UseCompanyUrlMixin)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount("#app")

<script>
import { eventBus } from "../../_shared/constants";
import { getArticle } from "./api";
import Article from "../article";
import PublicLayout from "../../views/layouts/PublicLayout";

export default {
  components: {
    Article
  },
  data() {
    return {
      article: null
    };
  },
  methods: {
    fetchArticle() {
      const articleToken = this.$router.history.current.params.articleToken;
      getArticle(articleToken).then((response) => {
        this.article = response.data.data;
      });
    },
    handleClickBack() {
      window.history.back();
    }
  },
  mounted() {
    this.fetchArticle();
  }
};
</script>

<template>
  <div class="wrapper content-wrapper">
    <div class="content-col">
      <div class="content-col-header">
        <div class=" col-wrapper">
          <div class="left-col">
            <div class="col-header-icons"></div>
          </div>
          <div class="right-col"></div>
        </div>
      </div>

      <div class="content-col-body article-list-view">
        <Article
          :article="article"
          v-if="article"
          :publicView="true"
          :article-specific-id="article.header.id"
        />
      </div>
    </div>
  </div>
</template>

<style></style>

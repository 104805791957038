<template>
  <div class="toc-entry">
    <div class="listing-item">
      <div class="article-item__body pvw-wrapper">
        <h6 class="article-item-category_name" v-if="article.category_name">
          {{ article.category_name }}
        </h6>
        <div class="pvw-headline">
          <h6
            article-item__body__title
            v-if="article"
            @click="setAsMainArticle"
          >
            {{ article.title }}
          </h6>
        </div>
        <div class="pvw-content">
          <div
            v-html="article.excerpt"
            @click="setAsMainArticle"
            class="article-item__body__excerpt"
          ></div>
        </div>
        <div class="col-wrapper pvw-actions">
          <div class="info-col">
            <ul class="link-icons">
              <li class="fs125">
                <span class="icon icon-group" v-if="isGroupArticle"></span>
                <span class="icon icon-newspaper" v-if="isPrintArticle"></span>
                <span class="icon icon-online" v-if="isOnlineArticle"></span>
                <span class="icon icon-radiotv" v-if="isTvRadioArticle"></span>
              </li>
            </ul>
            <ul class="quelle" v-if="article">
              <li>{{ article.source_name }}</li>
              <li>
                {{ article.source_publishing_date | moment("DD.MM.YYYY") }}
              </li>
            </ul>
          </div>
          <div class="action-col">
            <div class="listing-item-selector">
              <div class="more-horizontal-wrapper">
                <div class="more-horizontal-actions">
                  <div class="a-element" alt="PDF generieren">
                    <i class="icon icon-generate-pdf" @click="downloadPdf"></i>
                  </div>
                </div>
                <span class="icon icon-more-horizontal"></span>
              </div>
              <div class="iom-checkbox-wrapper">
                <!-- <ArticleSelectionCheckbox :article="article" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ArticleSelectionCheckbox from "../_shared/article-selection-checkbox";
import { downloadArticlesPdfRequest } from "../../_shared/api";

import {
  eventBus,
  EVENT_SHOW_GLOBAL_LOADING,
  EVENT_HIDE_GLOBAL_LOADING,
  EVENT_SELECT_SINGLE_ARTICLE
} from "../../_shared/constants";
export default {
  components: {
    //ArticleSelectionCheckbox
  },
  props: {
    article: Object,
    mainArticleType: null
  },
  methods: {
    setAsMainArticle() {
      if(this.mainArticleType == "bundle_article" && this.article.object_type =="group_article" && this.article.group_children){
        this.$emit("changeMainArticleComplete", this.article.group_children);
      }else{
        this.$emit("changeMainArticle", this.article.id);
      }
    },
    downloadPdf() {
      eventBus.$emit(EVENT_SHOW_GLOBAL_LOADING);
      downloadArticlesPdfRequest([this.article.id]).then(() => {
        eventBus.$emit(EVENT_HIDE_GLOBAL_LOADING);
      });
    }
  },
  computed: {
    isGroupArticle(){
      if (this.article && this.article.object_type == "group_article") {
        return true;
      }
      return false;
    },
    isPrintArticle() {
      if (this.article && this.article.e_media_type == "emt_print") {
        return true;
      }
      return false;
    },
    isOnlineArticle() {
      if (this.article && this.article.e_media_type == "emt_online") {
        return true;
      }
      return false;
    },
    isTvRadioArticle() {
      if (this.article && this.article.e_media_type == "emt_tv_radio") {
        return true;
      }
      return false;
    }
  }
};
</script>

<style></style>

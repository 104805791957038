<script>
export default {
};
</script>

<template>
  <div class="wrapper content-wrapper">
    <div class="content-col">
      <div class="content-col-body article-list-view">
        <br />
        <br />
        <br />

        <h5>Access Denied</h5>
      </div>
    </div>
  </div>
</template>

<style></style>

const state = () => ({
  mediaMirrorDate: new Date()
})

// getters
const getters = {
  getMediaMirrorDate:(state) => {
    return state.mediaMirrorDate;
  }
}

// mutations
const mutations = {
  setMediaMirrorDate (state, date) {
    state.mediaMirrorDate = date
  }
}

// actions
const actions = {
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<script>
import axios from "axios"
import {
  API_BASE_URL,
  COMPANY_TOKEN_KEY,
} from "./_shared/constants"

const defaultLayout = "DefaultLayout"

export default {
  name: "App",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout

      console.log(layout)
      return () => import(`@/views/layouts/${layout}.vue`)
    }
  },
  methods: {
    setupAxios() {
      axios.interceptors.request.use((config) => {
        const token = this.getCompanyToken()
        config.headers.Authorization = `token ${token}`
        config.baseURL = API_BASE_URL

        return config
      })
    },
    getCompanyToken() {
      return window[COMPANY_TOKEN_KEY]
    },
    setCompanyToken(token) {
      window[COMPANY_TOKEN_KEY] = token
    },
    loadLocale() {
      let locale = localStorage.getItem("locale");
      if (locale && locale != null) {
        this.$i18n.locale = locale;
      }
    },
  },
  created() {
    this.setCompanyToken(this.$router.history.current.params.companyToken)
    this.setupAxios()
  },
  mounted() {
    this.loadLocale()
  },
}
</script>

<template>
  <div id="app">
    <component :is="layout">
      <!-- <Alert /> -->
      <router-view />
    </component>
  </div>
</template>

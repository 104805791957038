<script>
import ArticleListingItem from "../_shared/article-listing-item";

export default {
  props: {
    article: Object,
  },
  components: {
    ArticleListingItem,
  },
};
</script>

<template>
  <div class="search-result--item">
    <ArticleListingItem
      :article="article"
      :display-category="true"
    />
  </div>
</template>

<style></style>

<script>
import {
  COMPANY_TOKEN_KEY,
} from "../_shared/constants"

export default {
  methods: {
    $_routesMixin_companyUrl(url,reset = 0) {
      const token = window[COMPANY_TOKEN_KEY]

      return `/${token}/${url}`
    },
    $_routesMixin_companySearchUrl(reset){
      const token = window[COMPANY_TOKEN_KEY]

      return `/${token}/search/${reset}`
    }
  },
};
</script>

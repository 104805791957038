<script>
import ArticleListingItem from "../article-listing-item"

export default {
  components: {
    ArticleListingItem,
  },
  props: {
    articles: Array,
    categoryId: String,
    section: String,
    displayCategory: {
      type: Boolean,
      default: false,
    },
    displayActions: {
      type: Boolean,
      default: true,
    },
    displaySelectionCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sortedArticles() {
      return this.articles;

      // no priority sorting
      // let articles = [...this.articles]

      // // sorting by priority
      // articles.sort(function(a, b) {
      //   return b.header.source_priority - a.header.source_priority;
      // });

      // let companyLocale = localStorage.getItem("companyLocale");

      // const companyLocaleArticles = articles.filter((article)=>{
      //   return article.header.language == companyLocale
      // })

      // const otherLocaleArticles = articles.filter((article)=>{
      //   return article.header.language != companyLocale
      // })

      // return [...companyLocaleArticles, ...otherLocaleArticles]
    },
  },
  methods: {},
  mounted() {},
}
</script>

<template>
<div class="last-no-border">
  <ArticleListingItem
    v-for="(item, index) in sortedArticles"
    :article="item"
    :key="index"
    :categoryId="categoryId"
    :section="section"
    :displayCategory="displayCategory"
    :displayActions="displayActions"
    :displaySelectionCheckbox="displaySelectionCheckbox"
  />
</div>
</template>

import axios from "axios"

export const getArticles = (ids = []) => {
  return axios.get(`/api/intranet/v1/newsletter/articles?article_ids=${ids.join(',')}`)
}

export const getArticlesFromList = (ids = []) => {
  return axios.get(`/api/intranet/v1/articles/list?articles=${ids.join(',')}`)
}

export const sendArticles = (newsletter, articleIds ) => {
  return axios.post(`/api/intranet/v1/newsletter/send`,
    {
      ...newsletter,
      article_ids: articleIds,
    }
  )
}

import Vue from 'vue'
import Vuex from 'vuex'
import search from './components/search/store'
import media_mirror from './components/media-mirror/store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    search,
    media_mirror
  },
  strict: debug,
})

import en from "./en.json";
import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";

const localizations = {
  en: en,
  de: de,
  fr: fr,
  it: it
};

export default localizations;

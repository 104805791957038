<script>
import "../../../_shared/polyfill";
import { nanoid } from "nanoid";

import {
  eventBus,
  SECTION_CATEGORY,
  EVENT_SELECT_SINGLE_ARTICLE,
  EVENT_UNCHECK_SELECT_ALL,
} from "../../../_shared/constants";
import ArticleListing from "../article-listing";

export default {
  name: "article-category",
  components: {
    ArticleListing,
  },
  props: {
    category: Object,
    position: Number,
  },
  data() {
    return {
      categoryId: nanoid(11),
      allArticlesSelected: false,
      visible: false,
      section: SECTION_CATEGORY,
    };
  },
  computed: {
    collapseId() {
      return `article-category-collpase-${this.category.category.id}`;
    },
    articleCount() {
      if (this.articles) {
        return this.articles.length;
      }
      return 0;
    },
    categoryName() {
      return this.category.category.name;
    },
    articles() {
      return this.category.entries.data;
    },
    categoryIdentifier() {
      return nanoid(11);
    },
  },
  mounted() {
    if (this.position < 1000) {
      this.visible = true;
    }

    eventBus.$on(EVENT_UNCHECK_SELECT_ALL, this.handleUncheckSelectAll);
  },
  methods: {
    handleUncheckSelectAll(section, id) {
      if (section != SECTION_CATEGORY) return;

      if (this.category.category.id == id) {
        this.allArticlesSelected = false;
      }
    },
    toggleAllArticlesSelected(val) {
      this.allArticlesSelected = val;

      this.articles.forEach((article) => {
        eventBus.$emit(
          EVENT_SELECT_SINGLE_ARTICLE,
          article.header.id,
          this.allArticlesSelected
        );
        
        if (article.articles) {
          article.articles.forEach((child) => {
            if (child.group_children) {
              child.group_children.articles.forEach((child_of_light) => {
                eventBus.$emit(
                  EVENT_SELECT_SINGLE_ARTICLE,
                  child_of_light.id,
                  this.allArticlesSelected
                );
              });
            } else {
              eventBus.$emit(
                EVENT_SELECT_SINGLE_ARTICLE,
                child.id,
                this.allArticlesSelected
              );
            }
          });
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <b-collapse visible class="article-category" :id="collapseId">
      <div class="collapse-header" role="tab">
        <div class="col-wrapper">
          <div class="full-col">
            <h4>
              <span class="title">{{ this.categoryName }}</span
              ><span class="articlecount"
                >{{ this.articleCount }} {{ $t("words.article") }}</span
              >
            </h4>
          </div>
        </div>
      </div>
      <b-collapse v-model="visible" :id="categoryIdentifier">
        <div class="article-listing__content">
          <div class="category-selector">
            <div class="iom-checkbox-wrapper">
              <b-form-checkbox
                v-model="allArticlesSelected"
                @change="toggleAllArticlesSelected"
                class="label-before"
                plain
              >
                {{ $t("media_summary.category.select_all") }}
              </b-form-checkbox>
            </div>
          </div>

          <ArticleListing
            :articles="articles"
            :categoryId="categoryIdentifier"
            :section="section"
          />
        </div>
      </b-collapse>
    </b-collapse>
  </div>
</template>

<style>
.article-category {
  background: #fff;
}
</style>>
export const uniqArray = (array) => {
  return Array.from(new Set(array))
}

export const removeValueFromArray = (array, value) => {
  const index = array.indexOf(value)

  if (index > -1) {
    array.splice(index, 1)
  }

  return array
}

export const getCollectionFromLocalStorage = (key, defaultValues = []) => {
  const values = JSON.parse(localStorage.getItem(key))

  if (values) return values

  return defaultValues
}

export const setCollectionToLocalStorage = (key, values = null) => {
  if (values == null) localStorage.removeItem(key)

  localStorage.setItem(key, JSON.stringify(values))
}

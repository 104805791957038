import axios from "axios";
import FileSaver from "file-saver";

export const downloadFile = (url, fileName) => {
  return axios({
    url: url,
    method: "GET",
    responseType: "blob" // important
  }).then(response => {
    var blob = new Blob([response.data]);
    FileSaver.saveAs(blob, fileName, { autoBom: true });

    return true;
  });
};

export const downloadArticlesPdfRequest = articleIds => {
  const url = `/api/frontend/v1/pdf/generate?article_id=${articleIds.join(
    ","
  )}`;

  return downloadFile(url, "pdf_export.pdf");
};

export const getSettingRequest = () => {
  return axios.get("/api/intranet/v1/setting")
}

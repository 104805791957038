<script>
import platform from "platform";
import { searchRequest, getSearchOptionsRequest } from "./api";
import SearchResultEntry from "./search_result_entry";
import DateRangePicker from "../_shared/daterange";
import Multiselect from "vue-multiselect";
import {
  eventBus,
  EVENT_RESET_SEARCH_DATEPICKER,
} from "../../_shared/constants";

export default {
  components: {
    SearchResultEntry,
    DateRangePicker,
    Multiselect,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 0,
      searchQuery: "",
      searchResults: [],
      searchResultsTotalCount: 0,
      selectedCategory: [],
      selectedRegion: [],
      selectedLanguage: [],
      selectedMediaType: [],
      selectedTopic: [],
      selectedSource: [],
      selectedKanton: [],
      selectedPeriod: null,
      selectedAuthor: null,
      optionsCategories: [],
      optionsMediaTypes: [],
      optionsMetaTopicDossiers: [],
      optionsMetaSources: [],
      optionsMetaRegions: [],
      optionsMetaKantons: [],
      filterStartDate: null,
      filterEndDate: null,
      searched: false,
      showEnableFilterButton: false,
      showFilters: true,
    };
  },
  mounted() {
    if(this.$router.history.current.params.reset == "1"){
      this.reset();
    }
    
    this.fetchSearchOptions();
    if (this.isMobileView) {
      this.showEnableFilterButton = true;
      this.showFilters = false;
    } else {
      this.showEnableFilterButton = false;
      this.showFilters = true;
    }
  },
  computed: {
    isMobileView() {
      let isMobile = true; // window.matchMedia("only screen and (max-width: 960px)")

      let family = platform.os.family;
      if (family.indexOf("Win") != -1) isMobile = false;
      if (family.indexOf("OS X") != -1) isMobile = false;
      if (family.indexOf("Linux") != -1) isMobile = false;

      //return true; // anschalten für Mobil
      return isMobile;
    },
    displayResetFilter() {
      if (this.selectedCategory.length > 0) return true;
      if (this.selectedKanton.length > 0) return true;
      if (this.selectedTopic.length > 0) return true;
      if (this.selectedRegion.length > 0) return true;
      if (this.selectedLanguage.length > 0) return true;
      if (this.selectedMediaType.length > 0) return true;
      if (this.selectedSource.length > 0) return true;
      if (this.filterStartDate != null) return true;
      if (this.filterEndDate != null) return true;
      return false;
    },

    optionsLanguages() {
      let t = [];
      t.push({ id: "de", name: this.$i18n.t("languages.de") });
      t.push({ id: "fr", name: this.$i18n.t("languages.fr") });
      t.push({ id: "it", name: this.$i18n.t("languages.it") });
      return t;
    },
    areSearchResultsAvailable() {
      if (this.searchResults.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    enableFilterButtonClicked() {
      this.showFilters = true
      this.showEnableFilterButton = false
    },
    reset() {
      this.searchQuery = "";
      this.currentPage = 1;
      this.perPage = 0;
      this.searchResults = [];
      this.searchResultsTotalCount = 0;
      this.selectedCategory = [];
      this.selectedRegion = [];
      this.selectedKanton = [];
      this.selectedLanguage = [];
      this.selectedMediaType = [];
      this.selectedSource = [];
      this.selectedTopic = [];
      this.selectedPeriod = null;

      this.filterStartDate = null;
      this.filterEndDate = null;
      this.searched = false;

      if (this.isMobileView) {
        this.showEnableFilterButton = true;
        this.showFilters = false;
      } else {
        this.showEnableFilterButton = false;
        this.showFilters = true;
      }

      eventBus.$emit(EVENT_RESET_SEARCH_DATEPICKER);
      this.$store.dispatch("search/reset");
      console.log("reset done");
    },
    pageChanged(page) {
      this.currentPage = page;
      this.doSearch();
    },
    dateChanged(startDate, endDate) {
      this.filterStartDate = startDate;
      this.filterEndDate = endDate;
    },
    fetchSearchOptions() {
      getSearchOptionsRequest().then((response) => {
        this.optionsCategories = response.data.categories;
        this.optionsMediaTypes = response.data.media_types;
        this.optionsMetaSources = response.data.sources;
        this.optionsMetaRegions = response.data.regions;
        this.optionsMetaKantons = response.data.kantons;
        this.optionsMetaTopicDossiers = response.data.topic_dossiers;

        this.loadFilters();
        if (this.searchQuery && this.searchQuery.length > 0) {
          this.doSearch();
        }
      });
    },
    loadFilters() {
      const filters = this.$store.getters["search/filters"];
      const sq = this.$store.getters["search/searchQuery"];

      this.selectedCategory = this.optionsCategories.filter((item) => {
        return filters.category.indexOf(item.id) > -1;
      });

      this.selectedMediaType = this.optionsMediaTypes.filter((item) => {
        return filters.media_type.indexOf(item) > -1;
      });

      this.selectedSource = this.optionsMetaSources.filter((item) => {
        return filters.source.indexOf(item) > -1;
      });

      this.selectedRegion = this.optionsMetaRegions.filter((item) => {
        return filters.region.indexOf(item) > -1;
      });

      this.selectedKanton = this.optionsMetaKantons.filter((item) => {
        return filters.kanton.indexOf(item) > -1;
      });

      this.selectedTopic = this.optionsMetaTopicDossiers.filter((item) => {
        return filters.topic_dossier.indexOf(item) > -1;
      });

      this.searchQuery = sq;
      this.filterStartDate = filters.start_date;
      this.filterEndDate = filters.end_date;
    },
    doSearch() {
      let payload = {};
      payload.sq = this.searchQuery;
      payload.page = this.currentPage;
      payload.filters = {};
      payload.filters.category = this.selectedCategory.map((item) => item.id);
      payload.filters.media_type = this.selectedMediaType.map(
        (item) => item.id
      );
      payload.filters.start_date = this.filterStartDate;
      payload.filters.end_date = this.filterEndDate;
      payload.filters.topic_dossier = this.selectedTopic.map((item) => item.id);
      payload.filters.source = this.selectedSource.map((item) => item.id);
      payload.filters.kanton = this.selectedKanton.map((item) => item.id);
      payload.filters.region = this.selectedRegion.map((item) => item.id);
      payload.filters.language = this.selectedLanguage.map((item) => item.id);

      this.$store.dispatch("search/search", payload);

      searchRequest(payload).then((response) => {
        this.searchResults = response.data.results.data;
        this.searchResultsTotalCount = response.data.results_count;
        this.currentPage = response.data.page;
        this.perPage = response.data.per_page;
        this.searched = true;
      });
    },
  },
};
</script>

<template>
  <div class="wrapper content-wrapper">
    <div class="contentsection col-wrapper">
      <div class="content-col full-col">
        <div class="content-col-header">
          <div class="col-wrapper">
            <div class="left-col">
              <h5>{{ $t("navigation.search") }}</h5>
            </div>
            <div class="right-col"></div>
          </div>
        </div>
        <div class="content-col-body">
          <div class="search-fullwidth maxwidthwrapper">
            <div class="fullwidth-searchbar">
              <b-input
                v-model="searchQuery"
                :placeholder="$t('media_clippings.search_params.params')"
                @keyup.enter="doSearch"
              ></b-input>
              <div class="icon-wrapper desc-only">
                <div class="search-btn" @click="doSearch">
                  <span class="icon icon-search"></span>
                </div>
                <div class="tooltip-wrapper">
                  <!--<span class="icon icon-info"></span> -->
                </div>
              </div>
            </div>
            <div class="fullwidth-searchfilter" v-if="showFilters">
              <label class="mobile-only">{{ $t("search.category") }}</label>
              <multiselect
                v-model="selectedCategory"
                :options="optionsCategories"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.category')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                  >
                    {{ $t("search.category") }}:
                    {{ values.length }}
                  </span></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{
                $t("search.topic_dossier")
              }}</label>
              <multiselect
                v-model="selectedTopic"
                :options="optionsMetaTopicDossiers"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.topic_dossier')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ $t("search.topic_dossier") }}: {{ values.length }}</span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{ $t("search.region") }}</label>
              <multiselect
                v-model="selectedRegion"
                :options="optionsMetaRegions"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.region')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ $t("search.region") }}: {{ values.length }}</span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{ $t("search.kanton") }}</label>
              <multiselect
                v-model="selectedKanton"
                :options="optionsMetaKantons"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.kanton')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ $t("search.kanton") }}: {{ values.length }}</span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{ $t("search.language") }}</label>
              <multiselect
                v-model="selectedLanguage"
                :options="optionsLanguages"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.language')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ $t("search.language") }}: {{ values.length }}</span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{ $t("search.media_type") }}</label>
              <multiselect
                v-model="selectedMediaType"
                :options="optionsMediaTypes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.media_type')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ $t("search.media_type") }}: {{ values.length }}</span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{ $t("search.source") }}</label>
              <multiselect
                v-model="selectedSource"
                :options="optionsMetaSources"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('search.source')"
                label="name"
                track-by="id"
                :showLabels="false"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ $t("search.source") }}: {{ values.length }}</span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">{{ props.option.name }}</div>
                </template>
              </multiselect>
              <label class="mobile-only">{{ $t("search.time_range") }}</label>
              <DateRangePicker
                :label="$t('search.time_range')"
                @date_changed="dateChanged"
              ></DateRangePicker>
              <div class="mobile-search-button-wrapper">
                <a @click="reset" v-if="displayResetFilter"
                  ><span class="icon icon-deselect"></span
                  >{{ $t("search.reset") }}</a
                >
                <div class="tooltip-wrapper mobile-only">
                  <!--<span class="icon icon-info"></span>-->
                </div>
                <a class="btn btn-primary mobile-only" @click="doSearch">{{
                  $t("actions.search")
                }}</a>
              </div>

              
            </div>
            <div class="filter-toggle-wrapper mobile-only" v-if="showEnableFilterButton">
                <a class="btn btn-primary" @click="doSearch">{{
                  $t("actions.search")
                }}</a>
                <a
                class="filter-toggle"
                @click="enableFilterButtonClicked"
                ><span class="icon icon-filter"></span>{{ $t("search.enable_filter") }}</a>
                <div class="tooltip-wrapper" >
                  <span class="icon icon-info"></span>
                </div>

            </div>
            <div v-if="areSearchResultsAvailable" class="search-result">
              <div>
                <div class="search-result--counter">
                  {{ searchResultsTotalCount }} {{ $t("search.num_results") }}
                </div>
              </div>
              <div class="pagination-wrapper">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="searchResultsTotalCount"
                  :per-page="perPage"
                  @change="pageChanged"
                  align="right"
                  prev-text="<"
                  next-text=">"
                ></b-pagination>
              </div>
              <div>
                <SearchResultEntry
                  v-for="article in searchResults"
                  :key="article.id"
                  :article="article"
                ></SearchResultEntry>
              </div>
            </div>
            <b-row
              v-if="searched == true && !areSearchResultsAvailable"
              class="search-result"
            >
              <div class="col-12">
                <div class="search-result--counter">
                  {{ 0 }} {{ $t("search.num_results") }}
                </div>
              </div>
              <div class="col-12">
                {{ $t("search.no_results") }}
              </div>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

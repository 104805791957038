<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import moment from "moment";
import {
  eventBus,
  getSetting,
  EVENT_RESET_ARTICLE_SELECTION,
  EVENT_MEDIA_MIRROR_DATE_CHANGED
} from "../../_shared/constants";
import { downloadArticlesPdfRequest } from "../../_shared/api";

import ArticleCategory from "../_shared/article-category/index.vue";
import * as API from "./api";
import { getSelectedArticleIds,resetSelectedArticleIds } from "../../_shared/selection";

const DATEPICKER_DATE_FORMAT_OPTION = {
  year: "numeric",
  month: "long",
  day: "numeric"
};

export default {
  components: {
    ArticleCategory,
    Loading
  },
  props: [],
  data() {
    return {
      apiData: {},
      date: new Date(),
      allArticlesSelected: false,
      isLoading: false,
      dateFormatOption: DATEPICKER_DATE_FORMAT_OPTION,
      setting: {},
      dateToday: new Date()
    };
  },
  computed: {
    categories() {
      return this.buildCategories();
    }
  },
  methods: {
    dateSelected() {
      this.populateData();

      eventBus.$emit(EVENT_MEDIA_MIRROR_DATE_CHANGED, this.date);
      this.$store.commit("media_mirror/setMediaMirrorDate", this.date);
    },
    populateData() {
      this.isLoading = true;
      API.getArticles(this.date).then((response) => {
        this.apiData = response.data.data;
        this.isLoading = false;
      });
    },
    buildCategories() {
      if (!this.apiData.categories) {
        return [];
      }

      return this.apiData.categories.map((category) => {
        const newCategory = {
          category: category.category,
          entries: {
            data: []
          }
        };

        const entries = category.entries.data.map((entry) => {
          return this.buildCategoryEntry(entry);
        });

        newCategory.entries.data = entries;

        return newCategory;
      });
    },
    buildCategoryEntry(entry) {
      return {
        ...entry.article,
        publication: {
          comment: entry.comment,
          publish_date: entry.publish_date
        }
      };
    },
    formattedSelectedDate() {
      return moment(this.date).format("DD.MM.YYYY");
    },
    // eslint-disable-next-line no-unused-vars
    dateDisabled(dateString, date) {
      return !moment(date).isBetween(
        moment(this.dateToday).subtract(9, "day"),
        moment(this.dateToday)
      );
    },
    toggleAllArticlesSelected(val) {
      this.allArticlesSelected = val;

      eventBus.$emit(
        EVENT_SELECT_ALL,
        SECTION_CATEGORY,
        this.allArticlesSelected
      );
    },
    generatePdf() {
      const articleIds = getSelectedArticleIds();

      this.isLoading = true;
      resetSelectedArticleIds()
      downloadArticlesPdfRequest(articleIds).then(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.date = this.$store.state.media_mirror.mediaMirrorDate;
    this.populateData();
    this.setting = getSetting();

    eventBus.$on(EVENT_RESET_ARTICLE_SELECTION, () => {
      this.allArticlesSelected = false;
    });
  }
};
</script>

<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <div class="content-col">
      <div class="content-col-header">
        <div class=" col-wrapper">
          <div class="left-col">
            <h5 v-if="setting.media_mirror_title">
              {{ setting.media_mirror_title }}
            </h5>
            <h5 v-else>{{ $t("media_summary.media_summary") }}</h5>
            <div class="date-selection">
              <b-form-datepicker
                v-model="date"
                :date-disabled-fn="dateDisabled"
                :start-weekday="1"
                locale="de"
                @input="dateSelected"
                :date-format-options="{
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                }"
                :label-help="$t('datepicker.label_help')"
              >
              </b-form-datepicker>
            </div>
          </div>
          <div class="right-col">
            <div class="col-header-icons">
              <router-link :to="$_routesMixin_companyUrl('newsletter')">
                <span class="icon icon-send"></span>
              </router-link>

              <a href="javascript: void(0)" @click="generatePdf()">
                <span class="icon icon-generate-pdf"></span>
              </a>
            </div>
            <div class="iom-checkbox-wrapper">
              <!-- <b-form-checkbox v-model="selected" @change="checkboxChanged">
                  </b-form-checkbox> -->
            </div>
          </div>
        </div>
      </div>
      <div class="content-col-body">
        <div v-if="isLoading">
          <div class="spinnerwrapper">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else>
          <ArticleCategory
            v-for="(category, index) in categories"
            :category="category"
            :key="category.id"
            :position="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

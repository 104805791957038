import Vue from "vue"

export const eventBus = new Vue()
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const COMPANY_TOKEN_KEY = "COMPANY_TOKEN_KEY"
export const COMPANY_SETTING_KEY = "COMPANY_SETTING_KEY"

export const SECTION_CATEGORY = "SECTION_CATEGORY"

export const EVENT_RESET_SEARCH_DATEPICKER = "EVENT_RESET_SEARCH_DATEPICKER"

export const EVENT_RESET_ARTICLE_SELECTION = "EVENT_RESET_ARTICLE_SELECTION"
export const EVENT_SELECT_ALL = "EVENT_SELECT_ALL"
export const EVENT_UNCHECK_SELECT_ALL = "EVENT_UNCHECK_SELECT_ALL"
export const EVENT_SELECT_SINGLE_ARTICLE = "EVENT_SELECT_SINGLE_ARTICLE"
export const EVENT_MEDIA_MIRROR_DATE_CHANGED = "EVENT_MEDIA_MIRROR_DATE_CHANGED"

export const setSetting = (data) => {
  let params = {}

  if (data != null) {
    params = data;
  }

  localStorage.setItem(COMPANY_SETTING_KEY, JSON.stringify(params))
}

export const getSetting = () => {
  const data = localStorage.getItem(COMPANY_SETTING_KEY)
  if (data == null) return {}

  return JSON.parse(data)
}

<script>
import axios from "axios"

import {
  eventBus,
  SECTION_CATEGORY,
  EVENT_RESET_ARTICLE_SELECTION,
  EVENT_SELECT_ALL,
  EVENT_UNCHECK_SELECT_ALL,
  EVENT_SELECT_SINGLE_ARTICLE,
} from "../../_shared/constants"

import {
  selectArticle,
  deselectArticle,
  getSelectedArticleIds,
} from "../../_shared/selection"

export default {
  props: {
    article: Object,
    section: String,
  },
  data() {
    return { selected: false }
  },
  mounted() {
    this.updateSelected()

    eventBus.$on(EVENT_RESET_ARTICLE_SELECTION, () => {
      this.selected = false
    })

    eventBus.$on(EVENT_SELECT_ALL, (section, selected, params) => {
      if (this.selected == selected) return
      if (section != this.section) return
      if (section == SECTION_CATEGORY && params) {
        return this.handleCategoryAllSelection(selected, params)
      }

      this.selectArticle(selected)
    })

    eventBus.$on(
      EVENT_SELECT_SINGLE_ARTICLE,
      this.handleEventSelectSingleArticle
    )
  },
  methods: {
    updateSelected() {
      const articleIds = getSelectedArticleIds()

      if (articleIds.indexOf(this.article.id) !== -1) {
        this.selected = true
      }
    },
    handleEventSelectSingleArticle(id, selectState, force = true) {
      if (this.article.id != id) return

      if (force) {
        this.selectArticle(selectState)
      } else {
        this.selected = selectState
      }
    },
    selectArticle(selected) {
      this.selected = selected

      if (this.selected) {
        selectArticle(this.article.id)
      } else {
        deselectArticle(this.article.id)
      }

      console.log(getSelectedArticleIds())
    },
    handleCategoryAllSelection(selected, params) {
      if (params.id != this.article.category_id) return

      this.selectArticle(selected)
    },
    checkboxChanged(new_value) {
      if (new_value != true) {
        eventBus.$emit(
          EVENT_UNCHECK_SELECT_ALL,
          SECTION_CATEGORY,
          this.article.category_id
        )
      }

      this.selectArticle(new_value)

      eventBus.$emit(
        EVENT_SELECT_SINGLE_ARTICLE,
        this.article.id,
        new_value,
        true
      )
    },
  },
}
</script>

<template>
  <b-form-checkbox v-model="selected" @change="checkboxChanged">
  </b-form-checkbox>
</template>

<style></style>

export const showInfoToast = (vueRef, body, title) => {
  vueRef.$bvToast.toast(body, {
    title: title,
    variant: "success",
    solid: true,
    autoHideDelay: 2000,
    appendToast: true
  })
}

export const showWarningToast = (vueRef, body, title) => {
  vueRef.$bvToast.toast(body, {
    title: title,
    variant: "warning",
    solid: true,
    appendToast: true
  })
}

import axios from "axios"
import moment from "moment"

export const getArticles = (date) => {
  let formattedDate = ""

  if (date) {
    formattedDate = moment(date).format("DD-MM-YYYY")
  }

  return axios.get(`/api/intranet/v1/media_mirror/category_articles?date=${formattedDate}`)
}

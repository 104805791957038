<script>
import { eventBus, COMPANY_TOKEN_KEY } from "../../../_shared/constants";
import moment from "moment";
import ArticleSelectionCheckbox from "../ArticleSelectionCheckbox";

export default {
  name: "AtomicView",
  components: {
    ArticleSelectionCheckbox
  },
  props: {
    visible: Boolean,
    position: Number,
    article: Object,
    publication: Object,
    categoryId: String,
    section: String,
    displayCategory: {
      type: Boolean,
      default: false
    },
    displayActions: {
      type: Boolean,
      default: true
    },
    displaySelectionCheckbox: {
      type: Boolean,
      default: true
    },
    mainArticleId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      itemSelected: false
    };
  },
  computed: {
    hasOrganizations(){
      if(this.article.organizations && this.article.organizations.length > 0){
        return true;
      }
      return false;
    },
    organizations(){
       const t = this.article.organizations.map(item => item.name)
       return t.join(", ")
    },
    title: function() {
      return this.article.title;
    },
    excerpt: function() {
      return this.article.excerpt;
    },
    source: function() {
      return this.article.source_name;
    },
    publishingDate: function() {

      return moment(this.article.source_publishing_date).format("DD.MM.YYYY");
    },
    isPrintArticle() {
      if (this.article && this.article.e_media_type == "emt_print") {
        return true;
      }
      return false;
    },
    isOnlineArticle() {
      if (this.article && this.article.e_media_type == "emt_online") {
        return true;
      }
      return false;
    },
    isTvRadioArticle() {
      if (this.article && this.article.e_media_type == "emt_tv_radio") {
        return true;
      }
      return false;
    }
  },
  created() {
    eventBus.$on(this.categoryId + ":articleSelectionToggle", (toggleValue) => {
      this.itemSelected = toggleValue;
    });
  },
  mounted() {},
  methods: {
    openArticle() {
      this.$emit("triggerOpenArticle", this.article.id);
    }
  }
};
</script>

<template>
  <div>
    <div class="listing-item">
      <div class="article-item__checkbox"></div>
      <div class="article-item__body pvw-wrapper">
        <h6 class="article-item-category_name" v-if="displayCategory">
          {{ article.category_name }}
        </h6>
        <div class="pvw-headline">
          <h6 @click="openArticle" v-html="title"></h6>
        </div>
        <div class="pvw-content">
          <div v-html="excerpt" @click="openArticle"></div>
        </div>
        <div class="col-wrapper pvw-actions">
          <div class="info-col">
            <ul class="link-icons">
              <li>
                <span class="icon icon-newspaper" v-if="isPrintArticle"></span>
                <span class="icon icon-online" v-if="isOnlineArticle"></span>
                <span class="icon icon-radiotv" v-if="isTvRadioArticle"></span>
              </li>
            </ul>
            <ul class="quelle">
              <li class="bundle">Medienecho</li>
              <li>{{ source }}</li>
              <li v-if="hasOrganizations">{{organizations}}</li>
              <li>{{ publishingDate }}</li>
            </ul>
          </div>
          <div class="action-col">
            <!-- hier die actions einbauen -->
            <div class="listing-item-selector">
              <div class="iom-checkbox-wrapper" v-if="displaySelectionCheckbox">
                <ArticleSelectionCheckbox
                  :article="article"
                  :section="section"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="publication && publication.comment" class="comment-sent">
          <span class="icon icon-comment-bold"></span>{{ publication.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

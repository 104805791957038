<script>
import { getSettingRequest } from "../../_shared/api";
import { setSetting, getSetting } from "../../_shared/constants";

export default {
  data() {
    return {
      logo: require("../../assets/logo_medviu.svg"),
      setting: {}
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Intranet Medviu"
    // all titles will be injected into this template
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", this.$i18n.locale);
      this.$router.go(0);
    },
    classForLocale(locale) {
      if (this.$i18n.locale == locale) return "current";

      return "";
    }
  },
  mounted() {
    this.$gtag.pageview({ page_path: this.$route.path });
  },
  created() {
    getSettingRequest().then((response) => {
      setSetting(response.data.data);

      this.setting = getSetting();
      if (localStorage.getItem("locale") == null) {
        this.$i18n.locale = response.data.data.language;
      }
    });
  }
};
</script>

<template>
  <div class="public-view">
    <div class="public-view-wrapper">
      <div class="public-view-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-8">
              <div class="logo"></div>
            </div>
            <div class="col-4">
              <div class="languages">
                <b-link @click="changeLocale('de')">DE</b-link>
                <b-link @click="changeLocale('fr')"> FR </b-link>
                <b-link @click="changeLocale('it')"> IT</b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="public-view-article-wrapper">
        <div class="contentsection">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="public-view-footer">
      <div class="infoline">
        <span class="about">{{ $t("about.sangit") }}</span
        ><span>, </span>
        <a href="tel: +41 71 330 03 75">{{ $t("about.phone") }}</a
        ><span>, </span>
        <a href="mailto:contact@medviu.ch">{{
          $t("about.contact_mail")
        }}</a
        ><span>, </span>
        <a href="http://www.medviu.ch" target="_blank">{{
          $t("about.web")
        }}</a>
      </div>
    </div>
  </div>
</template>

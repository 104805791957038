<script>
import { eventBus } from "../../_shared/constants";
import {
  getSelectedArticleIds,
  resetSelectedArticleIds,
} from "../../_shared/selection";
import * as API from "./api";
import ArticleListing from "../_shared/article-listing";

export default {
  components: {
    ArticleListing,
  },
  data() {
    return {
      showSuccessMessage: false,
      articles: [],
      newsletter: {
        from: "",
        to: "",
        valid: false,
      },
      articleIds: getSelectedArticleIds(),
    };
  },
  computed: {
    articleCount() {
      if (this.articles) {
        return this.articles.length;
      }

      return 0;
    },
    validationErrorClass() {
      let className = "";
      let t = this.newsletter.to.replace(",", ";");
      let emails = t.split(";");

      emails.forEach((email) => {
        if (this.emailIsValid(email) == false) {
          className = "warning";
        }
      });

      if (this.newsletter.to && this.newsletter.to.length == 0) {
        className = "warning";
      }

      return className;
    },
    isSendButtonDisabled() {
      let disabled = false;
      let t = this.newsletter.to.replace(",", ";");
      let emails = t.split(";");

      emails.forEach((email) => {
        if (this.emailIsValid(email) == false) {
          disabled = true;
        }
      });

      if (this.newsletter.to && this.newsletter.to.length == 0) {
        disabled = true;
      }

      return disabled;
    },
  },
  methods: {
    emailIsValid(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.trim().toLowerCase());
    },
    loadSelectedArticles() {
      API.getArticlesFromList(this.articleIds).then((response) => {
        this.articles = response.data.data;
      });
    },
    handleClickAbort() {
      window.location.href = this.$_routesMixin_companyUrl("media-mirror");
    },
    handleClickBack() {
      window.history.back();
    },
    handleClickSend() {
      let cloneNewsletter = Object.assign({}, this.newsletter);
      let t = this.newsletter.to.replace(",", ";");
      cloneNewsletter.to = t.split(";");

      API.sendArticles(cloneNewsletter, this.articleIds).then((response) => {
        this.showSuccessMessage = true;
        resetSelectedArticleIds();
      });
    },
  },
  mounted() {
    this.loadSelectedArticles();
  },
};
</script>

<template>
  <div>
    <div class="wrapper content-wrapper">
      <div class="contentsection col-wrapper">
        <div class="content-col full-col">
          <div class="content-col-header">
            <div class="col-wrapper">
              <div class="left-col">
                <div class="col-header-icons">
                  <a
                    href="javascript: void(0);"
                    @click="handleClickBack()"
                    class="backlink"
                    ><span class="icon icon-arrow-left"></span
                    >{{ $t("actions.back") }}</a
                  >
                </div>
              </div>
              <div class="right-col"></div>
            </div>
          </div>
          <div class="content-col-body">
            <div class="maxwidthwrapper">
              <div class="newsletter mobile-padder">
                <h4 class="mt-1 mb-5 uppercase">
                  {{ $t("newsletter.send_articles") }}
                </h4>
                <div v-if="!showSuccessMessage">
                  <div>
                    <b-form-group :label="$t('intranet.newsletter.from')">
                      <b-form-input
                        v-model="newsletter.from"
                        placeholder="Hans Muster"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      :label="$t('intranet.newsletter.to')"
                      :class="validationErrorClass"
                    >
                      <b-form-input
                        v-model="newsletter.to"
                        placeholder="mail@mail.ch; mail2@mail.ch; ..."
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      :label="$t('intranet.newsletter.content_label')"
                    >
                      <b-form-textarea
                        v-model="newsletter.body"
                        rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>

                  <div class="articlecount">
                    {{ this.articleCount }} {{ $t("article.articles") }}
                  </div>

                  <ArticleListing
                    :articles="articles"
                    :displayActions="false"
                    :displaySelectionCheckbox="false"
                    :displayCategory="true"
                  />

                  <div class="py-4 text-right">
                    <a class="btn btn-secondary" @click="handleClickAbort">{{
                      $t("actions.cancel")
                    }}</a>
                    <b-button
                      class="btn btn-primary ml-4"
                      @click="handleClickSend"
                      :disabled="isSendButtonDisabled"
                      >{{ $t("actions.send") }}</b-button
                    >
                  </div>
                </div>
                <div v-else>
                  <div class="newsletter__success-message">
                    <h6>{{ $t("newsletter.sent_confirmation") }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep {
  .newsletter {
    background: #fff;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/home";
import MediaMirror from "../components/media-mirror";
import Newsletter from "../components/newsletter";
import ArticleView from "../components/article-view";
import PublicView from "../components/public-view";
import AccessDeniedView from "../components/access-denied";
import Search from "../components/search";

const DEFAULT_LAYOUT = "DefaultLayout";
const GUEST_LAYOUT = "GuestLayout";
const PUBLIC_LAYOUT = "PublicLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "appRoot",
    component: Home,
    meta: {
      layout: DEFAULT_LAYOUT,
    },
  },
  {
    path: "/access-denied",
    name: "accessDeniedView",
    component: AccessDeniedView,
    meta: {
      layout: GUEST_LAYOUT,
    },
  },
  {
    path: "/:companyToken",
    name: "companyRoot",
    component: MediaMirror
  },
  {
    path: "/:companyToken/media-mirror",
    name: "mediaMirror",
    component: MediaMirror
  },
  {
    path: "/:companyToken/newsletter",
    name: "newsletter",
    component: Newsletter
  },
  {
    path: "/:companyToken/articles/:slug/:specificArticleId?",
    name: "articleView",
    component: ArticleView
  },
  {
    path: "/:companyToken/search/:reset?",
    name: "search",
    component: Search
  },
  {
    path: "/:companyToken/public/articles/:articleToken",
    name: "publicView",
    component: PublicView,
    meta: {
      layout: PUBLIC_LAYOUT,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
